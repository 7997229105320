
import { useState } from 'react';

import { Audio } from '../context.js';

const AudioProvider = ({ children, playbackSpeed, setPlaybackSpeed }) => {
  const [playing, setPlaying] = useState(null);

  const stop = ({ id } = {}) => {
    //console.log(`stop ${id}`);

    if ((id && playing?.id === id) || (!!playing && !id)) {
      if (playing.type === 'element') {
        playing.audioSource.pause();
      } else {
        playing.audioSource.stop();
      }
      setPlaying(null);
    }
  };

  const play = async ({ audioSource, id, type }) => {
    //console.log(`play ${id}`);

    if (playing) {
      stop({ id: playing.id });
    }

    if (type === 'element') {
      audioSource.playbackRate = playbackSpeed;

      // HACK: Sometimes audio doesn't play correctly on the first call.
      if (audioSource.paused) {
        try {
          await audioSource.play();
        } catch (e) {}
      }
      setTimeout(async () => {
        if (audioSource.paused) {
          try {
            await audioSource.play();
          } catch (e) {}
        }
      }, 100);
    } else {
      audioSource.start();
    }

    audioSource.addEventListener('ended', () => setPlaying(null), { once: true });
    setPlaying({ audioSource, id, type });
  };

  const handlePlaybackSpeedChange = (newSpeed) => {
    newSpeed = Math.max(0.25, Math.min(3, newSpeed));
    if (playing) {
      playing.audioSource.playbackRate = newSpeed;
    }
    setPlaybackSpeed(newSpeed);
  };

  return (
    <Audio.Provider value={{
      playing,
      play,
      stop,
      playbackSpeed,
      setPlaybackSpeed: handlePlaybackSpeedChange,
    }}>
      {children}
    </Audio.Provider>
  );
};

export default AudioProvider;
